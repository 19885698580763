// 判断类型
export function typeOf (obj) {
    const toString = Object.prototype.toString;
    const map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    };
    return map[toString.call(obj)];
}
// 转换日期
export function formatDate (num, format = 'YY-MM-DD hh:mm:ss') { //转换日期格式
    if (!num) {
        return ''
    }
    let date = new Date(num * 1)
    let map = {
        //Y:date.getFullYear(),//单独处理
        M: date.getMonth() + 1,
        D: date.getDate(),
        h: date.getHours(),
        m: date.getMinutes(),
        s: date.getSeconds()
    };
    return format.replace(/([YMDhms])+/g, function (w, t) {
        let v = map[t]
        if (v !== undefined) {
            if (w.length > 1) {
                return ('0' + v).slice(-2)
            }
            return v
        } else if ('Y' === t) {
            return (date.getFullYear() + '').slice(-w.length * 2)
        }
        return w
    })
}
//字符串转long毫秒
export function dateStrToLong (dateStr) {
    if (dateStr) {
        return new Date((dateStr).replace(/-/g, '/')).getTime()
    }
    return 0
}
export function debounce (fn, wait = 500, immediate = false) {
    let timer
    return function () {
        let context = this,
            args = arguments,
            later = () => {
                timer = null
                if (!immediate) {
                    fn.apply(context, args)
                }
            },
            callNow = immediate && !timer;
        clearTimeout(timer)
        timer = setTimeout(later, wait)
        if (callNow) {
            fn.apply(context, args)
        }
    }
}
export function throttle (fn, duration = 500) {
    let __self = fn,
        timer = null,
        firstTime = true;
    return function () {
        if (firstTime) {
            firstTime = false
            return __self.apply(this, arguments)
        }
        if (timer) {
            return
        }
        timer = setTimeout(() => {
            clearTimeout(timer)
            timer = null
            __self.apply(this, arguments)
        }, duration)
    }
}
export function quickSort (arr, compareFn) {
    if (arr.length <= 1) {
        return arr
    }
    let pivotIdx = Math.floor(arr.length / 2),
        pivot = arr.splice(pivotIdx, 1)[0],
        left = [],
        right = [];
    for (let a of arr) {
        if (compareFn(a, pivot) < 0) {
            left.push(a)
        } else {
            right.push(a)
        }
    }
    return quickSort(left, compareFn).concat([pivot], quickSort(right, compareFn))
}
export function hrefEncode (str) {
    let reg = '(\\s|\\n|<br>|^)(http(s)?://.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?(&|&amp;)//=]*)';
    return str.replace(new RegExp(reg, 'ig'), function (match) {
        let hasProtocol = /^http(s)?:\/\//.test(match),
            protocol = hasProtocol ? '' : 'http://';
        return '<a target="_blank" href="' + protocol + match.replace(/^(\s|\n)/, '') + '">' + match + '</a>'
    })
}
// 数字转金额
export function toMoney (s) {
    s = parseFloat(s) + '';
    s = s.replace(/^(\d*)$/, '$1.');
    s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1');
    s = s.replace('.', ',');
    let re = /(\d)(\d{3},)/;
    while (re.test(s)) s = s.replace(re, '$1,$2');
    s = s.replace(/,(\d\d)$/, '.$1');
    return s.replace(/^\./, '0.')
}
// Element.prototype.matches
export function matches (el, selector) {
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector || function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this)
                return i > -1
        }
    }
    return Element.prototype.matches.call(el, selector)
}
// jquery parents
export function parents (el, selector = '*') {
    let parentsMatch = []
    while ((el = el.parentNode) !== null) {
        if (el !== window.document && matches(el, selector)) {
            parentsMatch.push(el)
        }
    }
    return parentsMatch
}
export function getCookie (name) {
    var arr, reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    if (document.cookie.match(reg)) {
        arr = document.cookie.match(reg)
        return unescape(arr[2])
    }
    return null
}
export function zzValidate (content = '', ruleStr, type) { //正则验证 ruleStr :如 "/^1[3-9]\d{9}$/"  字符串
    if (type == 11) {
        ruleStr = '(^[-0-9][0-9]*(.[0-9]+)?)$'
    } else if (!ruleStr || ruleStr.indexOf('|') == 0) {
        return true;
    }
    let reg = new RegExp(ruleStr)
    return reg.test(content + '')
}