<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-13 16:20:48
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-21 15:00:30
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\systemLog\index.vue
 * @Description: 
-->
<template>
	<div class="user">
		<div class="md-layout" v-loading="loading">
			<div class="search-container head-shadow">
				<div class="search-people search-household mt-10">
					<div class="search-form">
						<span class="search-form-text search-form-title">查 询</span>
						<el-input class="search-input search-household-input" v-model="searchParmas.keyword"
							placeholder="请输入关键字">
						</el-input>
					</div>
					<div class="search-btns">
						<md-button class="query-btn md-success" @click="searchList">
							<i class="el-icon-zoom-in el-icon"></i>
							查 询
						</md-button>
						<md-button class="rest-btn md-default" @click="clearSearch">
							<i class="el-icon-refresh-right el-icon"></i>
							重 置
						</md-button>
					</div>
				</div>
			</div>

			<div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
				<md-card class="md-card-plain">
					<!-- <md-card-header class="card-header" md-alignment="center">
						<el-button type="success" @click="addUser">添加</el-button>
					</md-card-header> -->
					<md-card-content class="card-content">
						<el-table :data="tableData">
							<el-table-column type="index" align="center" label="序号" width="100">
							</el-table-column>
							<el-table-column prop="username" label="账号名称" min-width="120">
							</el-table-column>
							<el-table-column prop="ip" label="IP" min-width="120">
							</el-table-column>
							<el-table-column prop="operation" label="操作" min-width="120">
							</el-table-column>
							<el-table-column prop="method" label="方法" min-width="120">
							</el-table-column>
							<el-table-column prop="createTime" label="时间" min-width="120">
								<template slot-scope="scope">
									{{ formatDate(scope.row.createTime) }}
								</template>
							</el-table-column>

							<!-- <el-table-column fixed="right" align="center" label="操作" width="240">
								<template slot-scope="scope">
									<el-button type="text"  @click="peopleAdmin(scope.row)">
										人员管理
									</el-button>
									<el-button type="text"  @click="editUser(scope.row)">
										编辑
									</el-button>
									<el-popconfirm @confirm="delHouse(scope.row)" confirm-button-text='确认'
										cancel-button-text='取消' icon="el-icon-info" icon-color="red"
										:title="`确认永久删除 ${scope.row.address} 吗？`">
										<el-button slot="reference" class="del-user" type="text" >删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column> -->
						</el-table>
						<el-pagination class="page" layout="total, prev, pager, next" :current-page="page.pageNum"
							@current-change="updatePagination" :page-size="page.pageSize" :total="page.total">
						</el-pagination>
						<!-- <pagination ref="pagination" :total="page.total" @nextPage="updatePagination" /> -->
						<!-- <el-pagination class="page" layout="total, prev, pager, next" :current-page="1"
							@current-change="updatePagination" :page-size="12" :total="1000">
						</el-pagination> -->
					</md-card-content>
				</md-card>
			</div>
		</div>
	</div>
</template>

<script>
// import { SimpleTable, OrderedTable } from "@/components";
import * as API from '@/api/systemLog.js'
// import Pagination from '@/components/pagination/index.vue'
import { formatDate } from '@/utils/assist.js'
export default {
	components: {
		// Pagination
	},
	data () {
		return {
			labelPosition: 'rigin',
			searchParmas: {
				keyword: ''
			},
			tableData: [],
			loading: false,
			page: {
				total: 0,
				pageNum: 1,
				pageSize: 10
			},
		}
	},
	created () {
		this.getSystemLog()
	},
	methods: {
		/**
		 * 获取列表
		 */
		async getSystemLog () {
			this.loading = true
			try {
				const { pageSize, pageNum } = this.page
				const data = await API.getSystemLog({
					pageNum,
					pageSize,
					...this.searchParmas
				})
				if (data.code === 200 && data.data) {
					this.tableData = data.data.list || []
					this.page.total = data.data.total
				}
				this.loading = false
			} catch (error) {
				this.loading = false
				console.error(error)
			}
		},
		searchList () {
			this.page.pageNum = 1
			this.getSystemLog()
		},
		clearSearch () {
			this.searchParmas = {
				keyword: ''
			}
			this.searchList()
		},
		/**
		 * 分页翻动
		 */
		updatePagination (page, pageSize, sort, sortOrder) {
			console.log('pagination has updated', page, pageSize, sort, sortOrder);
			this.page.pageNum = page
			this.getSystemLog()
		},
		formatDate (dateStr) {
			if (!dateStr) return '-'
			return formatDate(new Date(dateStr).getTime(), 'YY-MM-DD hh:mm')
		},
	},
}
</script>

<style lang="scss" scoped>
.card-content {
	background-color: #fff;
}

.auth-select {
	width: 100%;
}

.page {
	margin-top: 20px;
	text-align: right;
}

.del-user {
	padding-left: 12px;
}

.card-header {
	text-align: right;
	background-color: #eee;
}

.search-container {
	margin: 0px 15px 0;
	width: 100%;
	border-radius: 2px;
	// background: linear-gradient(180deg, #43D49C 3%, #14A874 99%);
	background-color: #fff;
	font-size: 16px;

	.search-people {
		display: flex;
		padding: 10px 15px 0;

		.search-form {
			display: flex;
			align-items: center;
			// flex: 1;
			color: #000;

			&-text {
				padding: 0 15px;
				min-width: 40px;
			}

			&-title {
				min-width: 80px;
			}
		}
	}

	.search-household {
		padding: 0px 15px 10px;
	}

	.search-btns {
		margin-left: 15px;
		margin-right: 2%;
		font-size: 18px;

		.rest-btn {
			margin-left: 20px;
		}

		.el-icon {
			margin-right: 8px;
		}

		::v-deep .md-button .md-button-content {
			font-size: 16px;
		}
	}

	.search-household-input {
		margin-right: 24px;
	}

	.search-input {
		min-width: 350px;
		// height: 32px;

		// ::v-deep .el-input__inner {
		// 	height: 32px;
		// 	line-height: 32px;
		// }
	}
}

.owner-form {
	position: relative;
	margin-top: 10px;
	padding: 30px 30px 20px 15px;
	border-radius: 2px;
	border: 1px solid #e6e6e6;

	.close {
		position: absolute;
		right: 5px;
		top: 5px;
		padding: 5px;

		&:hover {
			cursor: pointer;
			opacity: .7;
		}
	}
}
</style>