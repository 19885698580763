/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-30 15:46:51
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-11-30 15:47:22
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\systemLog.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const getSystemLog = (params) => {
	return Http({
		url: '/admin/admin/logList',
		method: 'get',
		params: params
	})
}